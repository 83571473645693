<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export default {
  provide() {
    return {
      // 用于刷新页面
      reload: this.reload,
    }
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  mounted() {
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
  },
  watch: {
    $route: function (to, from) {
      // console.log(to, from)
      window.sessionStorage.setItem('navIndex', to.meta.index)
      this.$i18n.locale = window.sessionStorage.getItem('language')
        ? window.sessionStorage.getItem('language')
        : 'jp'
        
    },
  },
}
</script>

<style lang="scss">
body {
  font-family:”Microsoft YaHei”,Arial,Helvetica,sans-serif,”宋体”;
}
</style>
