import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import jquery from 'jquery'
import VueLazyload from 'vue-lazyload'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import '@/plugins/index.js'
import '@/styles/common.scss'
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import '@/assets/icon/iconfont.css'
import 'swiper/swiper-bundle.css'
Vue.use(VueI18n)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(VueLazyload, { error: '', loading: '' })
var language = window.sessionStorage.getItem('language')
        ? window.sessionStorage.getItem('language')
        : 'jp'
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    if (language == 'jp') {
      document.title =
      to.meta.title2 + ' | ' + '新禾国际日本有限公司'
    }else if(language == 'en') {
      document.title =
      to.meta.title3 + ' | ' + '新禾国际日本有限公司'
    }else{
      document.title =
      to.meta.title + ' | ' + '新禾国际日本有限公司'
    }
  } else {
    document.title = '首页' + ' | ' +'新禾国际日本有限公司'
  }
  next()
});
Vue.config.productionTip = false

const messages = {
  'cn': require('@/lang/cn.js'),
  'en': require('@/lang/en.js'),
  'jp': require('@/lang/jp.js'),
}

const i18n = new VueI18n({
  locale: 'jp',
  messages,
  silentTranslationWarn: true,
})

new Vue({
  router,
  store,
  jquery,
  i18n,
  render: h => h(App)
}).$mount('#app')
