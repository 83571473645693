export const lang = {
    companyName: '新禾国际日本有限公司',
    companyName_en: 'Sinka Global Japan co., ltd',
    about: 'About',
    status: 'News',
    introduce: 'Introduce',
    recruit: 'Careers',
    contact: 'Contact ',
    bannerDesc: 'banner desc',
    more: 'more',
    advantage: 'Advantage',
    service: 'Our Services',
    cooperation: 'Our Partners',
    support: 'Support',
    serviceAgreement: 'Service Agreement',
    privacyStatement: 'Privacy Policy',
    contactInfo: 'contactInfo',
    contactName: '',
    contactAddress: '',
    fast: 'Fast',
    honest: 'Honest',
    enterprise: 'Progressive',
    multielement: 'Diversity',
    comapny_introduce: 'Company Introduction',
    company_name: 'Company Name',
    company: 'Japan Xinhe International Co., Ltd',
    found_time_name: 'Founded Date',
    found_time: '2019',
    legal_person_name: 'Legal Representative',
    legal_person: 'Wei Li - President / General Manager',
    funds_money: 'Startup capital',
    funds: '5,000,000 Yen',
    address_name: 'Company Address',
    address: 'B1F, K2 Plaza, Xinrong 1-12-3, Central District, Nagoya, Aichi County',
    branch_company: 'Branch company address',
    business_field_name: 'Business Scope',
    business_detail: 'Business details',
    recruit_info: 'Careers',
    recruit_position1: 'Foreign trade salesman',
    recruit_condition1: 'Requirements: Proficient in Japanese is required',
    work_content1: 'Scope of work: Develop foreign and domestic customers in Chinese and Japanese',
    recruit_position2: 'Translator',
    recruit_condition2: 'Requirements: JLPT Level 1',
    work_content2: 'Scope of work: Translate the product data of domestic and foreign affiliated companies',
    work_hours: 'Working hours: 9 a.m. to 6 p.m., rest on Saturday and Sunday',
    selectCountry: 'select country',
    name: 'Name',
    alphabet: 'alphabet',
    age: 'Age',
    gender: 'Gender',
    man: 'Male',
    woman: 'Female',
    phone: 'Phone Number',
    email: 'Email',
    education: 'Education ',
    work_experience: 'Work Experience',
    self_introduce: 'Self Introduction',
    application: 'Apply',
    consult: 'Questions',
    submit: 'Submit',
    warn_name: 'Enter your name',
    warn_alphabet: 'Enter alphabet',
    warn_age: 'Your Age',
    warn_age_info: 'Enter digital number',
    warn_phone: 'Enter Phone number',
    warn_email: 'Enter Your Email Address',
    warn_email_info: 'Invalid Email address',
    warn_consult: 'Enter your Questions'
}
