export const lang = {
    companyName: '新禾国际日本有限公司',
    companyName_en: 'Sinka Global Japan co., ltd',
    about: '会社情報',
    status: 'ニュース',
    introduce: '業務紹介',
    recruit: '採用情報',
    contact: 'お問い合わせ',
    bannerDesc: '世界を一つにして世界に行く',
    more: 'もっと知りたい',
    advantage: '私たちの強み',
    service: 'サービスを提供することができます',
    cooperation: 'パートナー',
    support: 'サポート',
    serviceAgreement: 'サービス契約',
    privacyStatement: 'プライバシー宣言',
    devDocument: '开发文档',
    contactInfo: '会社情報',
    contactName: '',
    contactAddress: '',
    fast: 'スピード',
    honest: '誠実',
    enterprise: '進取する',
    multielement: '多元化',
    comapny_introduce: '会社概要',
    company_name: '会社名',
    company: 'シンカグローバルジャパン株式会社',
    found_time_name: '創業',
    found_time: '2019年',
    legal_person_name: '代表者',
    legal_person: '李偉',
    funds_money: '資本金',
    funds: '5000000 円',
    address_name: '本社所在地',
    address: '愛知県名古屋市中区新栄1－12－3K２スクエアB１F',
    branch_company: '関西支社所在地',
    business_field_name: '主な事業内容',
    business_detail: '業務紹介',
    recruit_info: '求人情報：',
    recruit_position1: '職種: 営業職',
    recruit_condition1: '',
    work_content1: '仕事内容: 中国語、日本語使って日本国内、海外の法人営業',
    recruit_position2: '職種: 通訳',
    recruit_condition2: '資格：日本語１級',
    work_content2: '仕事内容: 会社の取り扱い製品の翻訳',
    work_hours: '',
    selectCountry: '国を選ぶ',
    name: 'お名前',
    alphabet: 'ふりがな',
    age: '年齢',
    gender: '性别',
    man: '男性',
    woman: '女性',
    phone: '電話番号',
    email: 'メールアドレス',
    education: '学歴',
    work_experience: '職務経歴',
    self_introduce: '自己紹介',
    application: '応じる',
    consult: 'お問い合わせ内容',
    submit: '送信',
    warn_name: 'お名前を入力してください',
    warn_alphabet: 'ふりがなを力してください',
    warn_age: '年齢を入力してください',
    warn_phone: '電話番号を入力してください',
    warn_email: 'メールアドレスを入力してください',
    warn_email_info: '正しいメールフォーマットを入力してください',
    warn_gender: '性別を選択してください',
    warn_consult: 'お問い合わせ内容を入力してください'

}