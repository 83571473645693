import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 重复点击导航路由报错解决方法
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layout" */ '@/views/Layout/Layout.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: {index: 0, title: '首页', title2: '首页', title3: 'home'}
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
        meta: {index: 1,title: '公司介绍', title2: '会社情報', title3: 'About'}
      },
      {
        path: '/status',
        name: 'status',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "status" */ '../views/Status.vue'),
        meta: {index: 2, title: '公司动态', title2: 'ニュース', title3: 'News'}
      },
      {
        path: '/introduce',
        name: 'introduce',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "introduce" */ '../views/Introduce.vue'),
        meta: {index: 3, title: '业务介绍', title2: '業務の紹介', title3: 'Introduce'}
      },
      {
        path: '/recruit',
        name: 'recruit',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "recruit" */ '@/views/Recruit.vue'),
        meta: {index: 4, title: '人才招聘', title2: '採用情報', title3: 'Careers'}
      },
      {
        path: '/contact',
        name: 'contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
        meta: {index: 5, title: '联系我们', title2: 'お問い合わせ', title3: 'Contact'}
      },
      {
        path: '/privacy',
        name: 'privacy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "privacy" */ '@/views/Privacy.vue'),
        meta: {index: 6, title: '隐私/服务协议', title2: 'サービス契約', title3: 'Agreement'}
      },
      {
        path: '/introduceDetail',
        name: 'introduceDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "introduce" */ '../views/introduceDetail.vue'),
        meta: {index: 7, title: '业务介绍', title2: '業務の紹介', title3: 'IntroduceDetail'}
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
