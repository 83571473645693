export const lang = {
        companyName: '新禾国际日本有限公司',
        companyName_en: 'Sinka Global Japan co., ltd',
        about: '公司介绍',
        status: '公司动态',
        introduce: '业务介绍',
        recruit: '人才招聘',
        contact: '联系我们',
        bannerDesc: '一一世界走向世界',
        more: '了解更多',
        advantage: '我们的优势',
        service: '可提供服务',
        cooperation: '合作伙伴',
        support: '支持',
        serviceAgreement: '服务协议',
        privacyStatement: '隐私声明',
        devDocument: '开发文档',
        contactInfo: '联系信息',
        contactName: '',
        contactAddress: '',
        fast: '快速',
        honest: '真诚',
        enterprise: '进取',
        multielement: '多元',
        comapny_introduce: '新禾简介',
        company_name: '企业名称',
        company: '日本新禾国际有限公司',
        found_time_name: '成立时间',
        found_time: '2019年',
        legal_person_name: '法人代表',
        legal_person: '董事长兼总经理 李伟',
        funds_money: '启动资金',
        funds: '5000000 円',
        address_name: '公司地址',
        address: '爱知县名古屋市中区新荣1-12-3 K2广场B1F',
        branch_company: '关西分社地址',
        business_field_name: '业务领域',
        business_detail: '业务详情',
        recruit_info: '招聘信息：',
        recruit_position1: '业务员',
        recruit_condition1: '要求：需要精通日语',
        work_content1: '工作内容：使用中文，日语开发国内外客户',
        recruit_position2: '翻译',
        recruit_condition2: '要求：日语一级',
        work_content2: '工作内容：翻译国内外关联公司的产品资料',
        work_hours: '工作时间：早9点到晚6点，周六周日休息',
        selectCountry: '选择国家',
        name: '姓名',
        alphabet: '拼音',
        age: '年龄',
        gender: '性别',
        man: '男',
        woman: '女',
        phone: '联系电话',
        email: '邮箱地址',
        education: '学历',
        work_experience: '工作经历',
        self_introduce: '自我介绍',
        application: '应聘',
        consult: '咨询问题',
        submit: '提交',
        warn_name: '请输入姓名',
        warn_alphabet: '请输入拼音',
        warn_age: '请输入年龄',
        warn_age_info: '请输入数字',
        warn_phone: '请输入固定电话号码或手机号码',
        warn_email: '请输入邮箱',
        warn_email_info: '请输入正确邮箱格式',
        warn_consult: '请输入咨询问题'
    
    }